<template>
  <div class="main">
    <div class="content-box">
      <el-form
        ref="form"
        :model="searchForm"
        label-suffix=":"
        label-width="120px"
      >
        <el-row :gutter="20" type="flex" style="flex-wrap:wrap;">
          <el-col :span="8">
            <el-form-item label="手机号" prop="mobile">
              <el-input
                clearable
                maxlength="30"
                placeholder="请输入"
                v-model="searchForm.mobile"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="站点名称" prop="plotId">
              <el-select
                clearable
                multiple
                filterable
                collapse-tags
                v-model="searchForm.plotId"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in plotList"
                  :key="item.id"
                  :label="item.plotName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单号" prop="orderNumber">
              <el-input
                placeholder="请输入"
                v-model="searchForm.orderNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单状态" prop="orderState">
              <el-select
                clearable
                v-model="searchForm.orderState"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in orderStateList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="充电状态" prop="chargeStatus">
              <el-select
                clearable
                collapse-tags
                v-model="searchForm.chargeStatus"
                placeholder="请选择"
                style="width: 100%;"
                multiple
              >
                <el-option
                  v-for="item in chargeStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间" prop="timeRange">
              <el-date-picker
                style="width: 100%;"
                v-model="searchForm.timeRange"
                type="daterange"
                clearable
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="!isOperator">
            <el-form-item label="运营商名称" prop="operatorId">
              <el-select
                clearable
                v-model="searchForm.operatorId"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in options"
                  :key="item.operatorId"
                  :label="item.operatorNm"
                  :value="item.operatorId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支付方式" prop="payType">
              <el-select
                clearable
                v-model="searchForm.payType"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in payWayList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div
            style="flex: 1 1 0%;display: flex;margin-right: 10px;justify-content: flex-end;"
          >
            <el-button
              @click="searchData"
              type="primary"
              style="margin-left:20px;"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button
              @click="resetSearch"
              plain
              style="margin-left:20px;"
              icon="el-icon-refresh"
              >重置</el-button
            >
            <el-button
              @click="exporthandlerTotal"
              plain
              style="margin-left:20px;"
              icon="el-icon-refresh"
              >导出</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60"
          fixed
        ></el-table-column>
        <el-table-column
          prop="orderNumber"
          label="订单号"
          align="center"
          min-width="280"
          fixed
        ></el-table-column>
        <el-table-column
          prop="pileCode"
          label="电桩编号"
          align="center"
          min-width="160"
          fixed
        ></el-table-column>
        <el-table-column
          prop="connectorNum"
          label="电枪编号"
          align="center"
          min-width="80"
          fixed
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="connectorCode"
          label="电枪编码"
          align="center"
          min-width="160"
          fixed
        ></el-table-column>
        <el-table-column
          prop="chargeStatus"
          label="充电状态"
          align="center"
          min-width="80"
        ></el-table-column>
        <!--                <el-table-column prop="messageState" label="下发状态" align="center" min-width="80"></el-table-column>-->
        <el-table-column
          prop="orderstate"
          label="订单状态"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="手机号"
          min-width="110"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="plotName"
          align="center"
          label="站点名称"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <!--                <el-table-column prop="cardName" align="center" label="卡号" min-width="60" :show-overflow-tooltip="true"></el-table-column>-->
        <!--                <el-table-column prop="starttime" label="开始时间" align="center" min-width="100"></el-table-column>-->
        <!--                <el-table-column prop="endtime" label="预计结束时间" align="center" min-width="100"></el-table-column>-->
        <!--                <el-table-column prop="realEndTime" label="实际结束时间" align="center" min-width="100"></el-table-column>-->
        <!--                <el-table-column prop="realEndTime" label="用时时长" :formatter="formatDuring" align="center" min-width="100"></el-table-column>-->
        <!--                <el-table-column prop="estimateAmount" label="预计费用" align="center" min-width="60"></el-table-column>-->
        <!--                <el-table-column prop="amount" label="实际金额" align="center" min-width="60"></el-table-column>-->
        <!--                <el-table-column prop="refundAmount" label="退款金额" align="center" min-width="60"></el-table-column>-->
        <!--<el-table-column prop="0" label="实际结束时间" align="center" min-width="100"></el-table-column>
        <el-table-column prop="0" label="预计金额"  align="center" min-width="80"></el-table-column>-->

        <el-table-column
          prop="realHourStr"
          label="充电时长"
          align="center"
          min-width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="consumePower"
          label="充电量"
          align="center"
          min-width="80"
          :formatter="
            (row, column, cellValue) => {
              if (cellValue) {
                return cellValue + '度';
              }
              return cellValue;
            }
          "
        >
        </el-table-column>
        <el-table-column
          prop="realAmountStr"
          label="订单金额"
          align="center"
          min-width="80"
          :formatter="
            (row, column, cellValue) => {
              if (cellValue) {
                return '￥' + cellValue;
              }
              return cellValue;
            }
          "
        ></el-table-column>
        <el-table-column
          prop="reliefTotalAmountStr"
          label="优惠金额"
          align="center"
          min-width="80"
          :formatter="
            (row, column, cellValue) => {
              if (cellValue) {
                return '￥' + cellValue;
              }
              return cellValue;
            }
          "
        >
        </el-table-column>
        <el-table-column
          prop="payTypeStr"
          label="支付方式"
          align="center"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="amountStr"
          label="实付金额"
          align="center"
          min-width="80"
          :formatter="
            (row, column, cellValue) => {
              if (cellValue) {
                return '￥' + cellValue;
              }
              return cellValue;
            }
          "
        ></el-table-column>
        <el-table-column
          prop="refundedAmountStr"
          label="退款金额"
          align="center"
          min-width="100"
          :formatter="
            (row, column, cellValue) => {
              if (cellValue) {
                return '￥' + cellValue;
              }
              return cellValue;
            }
          "
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <!--                <el-table-column prop="consumePower" label="耗电量" align="center" min-width="60"></el-table-column>-->
        <!--                <el-table-column prop="chargingCurrent" label="电流" align="center" min-width="60"></el-table-column>-->
        <!--                <el-table-column prop="chargingCdgl" label="功率" align="center" min-width="60"></el-table-column>-->
        <!--                <el-table-column prop="orderType" label="类型" align="center" min-width="70"></el-table-column>
                                      <el-table-column prop="pileName" label="电桩地址" align="center" min-width="80" :show-overflow-tooltip="true"></el-table-column>-->
        <!--                <el-table-column prop="agentName" label="代理商" align="center" min-width="80"></el-table-column>-->
        <el-table-column label="操作" align="center" width="160" fixed="right">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button @click="detailHandler(scope.row)">详情</el-button>
              <!--                            <el-link @click="changePort(scope.row)">端口更改</el-link>-->
              <!-- <i @click="deleteHandler(scope.row)" class="el-icon-delete"></i> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @pagination="handleCurrentChange"
        :background="false"
        :page.sync="page"
        :limit.sync="pageSize"
        :total="total"
      ></pagination>
    </div>

    <el-dialog title="变更端口" :visible.sync="dialogVisiblePort" width="40%">
      <div class="order">
        <el-form ref="formPort" :model="formDataPort" label-width="100px">
          <div class="order-1">
            <div class="text">订单号</div>
            {{ data.orderNumber }}
          </div>
          <div class="order-1">
            <div class="text">电桩号</div>
            {{ data.pileCode }}
          </div>
          <div class="order-1">
            <div class="text">端口号</div>
            {{ data.portName }}
          </div>
          <el-form-item
            class="nav-form-item"
            label="电桩编号"
            style="margin-top:20px;margin-left:-20px"
          >
            <el-input
              maxlength="14"
              type="text"
              v-model="formDataPort.pileCode"
              style="margin-left:20px;width:300px;"
              placeholder="请输入电桩编号"
              clearable
            />
          </el-form-item>
          <el-form-item
            class="nav-form-item"
            label="端口号"
            style="margin-top:20px;margin-left:-20px"
          >
            <el-input
              maxlength="6"
              type="text"
              v-model="formDataPort.portCode"
              style="margin-left:20px;width:300px;"
              placeholder="请输入端口号"
              clearable
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisiblePort = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimChangePort">确 定</el-button>
      </span>
    </el-dialog>
    <!-- { "id": 3312, "userName": "Sword", "price": null, "orderstate": "下单",
    "starttime": "2019-10-07 11:26:46", "endtime": "2019-10-07 15:26:46", "
    orderNumber": "86568679", "outTradeNo": null, "orderType": "按小时计费订单", "hour": "4" }-->
    <el-dialog title="查看详情" :visible.sync="dialogVisible" width="800px">
      <div class="order">
        <div class="order-1">
          <div class="text">订单号</div>
          {{ data.orderNumber }}
        </div>
        <div class="order-1">
          <div class="text">预计用时</div>
          {{ data.hour }}小时
        </div>
        <div class="order-1">
          <div class="text">名字</div>
          {{ data.userName }}
        </div>
        <div class="order-1">
          <div class="text">金额</div>
          {{ data.price }}
        </div>
        <div class="order-1">
          <div class="text">订单类型</div>
          {{ data.orderType }}
        </div>
        <div class="order-1">
          <div class="text">订单状态</div>
          {{ data.orderstate }}
        </div>

        <div class="order-1">
          <div class="text">开始时间</div>
          {{ data.starttime }}
        </div>
        <div class="order-1">
          <div class="text">完成时间</div>
          {{ data.endtime }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOrderList,
  getOrderData,
  refundOrder,
  ChangePort,
} from '@/api/order';
import { getPlotAllList } from '@/api/plot';
import { CHARGE_STATUS, ORDERSTATE_STATUS, PAY_WAY } from '@/utils/const';
import { getDataScopeList } from '@/api/user';
import { getToken } from '@/utils/auth';
import { isEmpty } from 'lodash-es';
import { getName } from '@/utils/auth';
import Pagination from '@/components/Pagination/index.vue';

export default {
  name: 'orderList',
  components: { Pagination },
  data() {
    return {
      orderStateList: ORDERSTATE_STATUS,
      payWayList: PAY_WAY,
      dialogVisible: false,
      options: [],
      tableData: [],
      cardNo: this.$route.query.cardNo,
      page: 1,
      pageSize: 10,
      loading: true,
      total: 0,
      url: process.env.VUE_APP_BASE_API,
      data: {},
      portCode: '',
      dialogVisiblePort: false,
      formDataRefund: {
        refundAmount: '',
        orderId: '',
        amount: '',
      },
      formDataPort: {
        pileCode: '',
        orderId: '',
        portCode: '',
      },
      plotList: [],
      searchForm: {
        mobile: '',
        operatorId: '',
        orderNumber: '',
        orderState: '',
        timeRange: [],
        plotId: '',
        chargeStatus: [],
        payType: '',
      },
    };
  },
  created() {
    this.getDataList();
    this.getDataScopeList();
    this.getPlotAllList();
  },
  computed: {
    chargeStatusOptions() {
      return CHARGE_STATUS;
    },
    isOperator() {
      try {
        let name = JSON.parse(getName());
        return name.userInfo.isOperator === 1;
      } catch (error) {}
    },
  },
  watch: {
    'searchForm.timeRange'(val) {
      if (val === null) {
        this.searchForm.timeRange = [];
      }
    },
  },
  methods: {
    formatDuring(row) {
      var res = '';
      if (row.realEndTime != null) {
        var mss = new Date(row.realEndTime) - new Date(row.starttime);
        var days = parseInt(mss / (1000 * 60 * 60 * 24));
        var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = (mss % (1000 * 60)) / 1000;
        if (hours > 0) res += hours + '时';
        /*if(minutes>0)*/
        res += minutes + '分';
        /*if(seconds>0)*/
        res += seconds + '秒';
      }

      return res;
    },
    changePort(row) {
      this.data = row;
      this.formDataPort.orderId = row.id;
      this.formDataPort.pileCode = '';
      this.formDataPort.portCode = '';
      this.dialogVisiblePort = true;
    },
    confimChangePort() {
      if (!this.formDataPort.pileCode || !this.formDataPort.portCode) {
        this.$message.error('请填写电桩编号或者端口号');
        return;
      }
      this.$refs.formPort.validate((valid) => {
        if (valid) {
          console.log(this.formDataPort);
          ChangePort(this.formDataPort).then((res) => {
            if (res.message.code != 0) {
              this.$message.error(res.message.message);
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.dialogVisiblePort = false;
              this.getDataList();
            }
          });
        }
      });
    },

    getDataScopeList() {
      getDataScopeList().then((res) => {
        if (res.data && res.data.length > 0) {
          this.options = [...res.data];
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let params = this.getSearchParams();
      if (params.chargeStatus)
        params.chargeStatus = params.chargeStatus.join(',');
      if (params.plotId) params.plotId = params.plotId.join(',');
      getOrderList(params)
        .then((res) => {
          if (res.message.status == 200) {
            this.tableData = [...res.data.records];
            this.total = res.data.total;
          }

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    handleCurrentChange({ page }) {
      this.page = page;
      this.getDataList();
    },
    detailHandler(row) {
      // let param = {
      //     orderNumber: row.orderNumber
      // }
      // getOrderData(param).then(res => {
      //     this.dialogVisible = true
      //     this.data = Object.assign({}, res.data)
      // })
      this.$router.push({
        path: `order/orderDetail/${row.orderNumber}`,
      });
    },

    exporthandlerTotal() {
      this.$confirm('确定将数据导出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let xhr = new XMLHttpRequest();
        xhr.open('post', this.url + '/sysOrder/download', true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('token', getToken());
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function() {
          if (this.status == 200) {
            var blob = this.response;
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = '订单数据' + new Date().getTime();
            a.click();
          }
          window.URL.revokeObjectURL(url);
        };
        xhr.send(JSON.stringify(this.getSearchParams()));
      });
    },
    getSearchParams() {
      let {
        chargeStatus,
        orderState,
        plotId,
        mobile,
        orderNumber,
        operatorId,
        payType,
      } = this.searchForm;
      let [startTime, endTime] = this.searchForm.timeRange;
      chargeStatus = isEmpty(chargeStatus) ? undefined : chargeStatus;
      plotId = isEmpty(plotId) ? undefined : plotId;
      let params = {
        chargeStatus,
        mobile: mobile || undefined,
        orderState: orderState || undefined,
        plotId: plotId || undefined,
        userOrNum: orderNumber || undefined,
        startTime,
        pageNo: this.page,
        pageSize: this.pageSize,
        endTime,
        userId: operatorId || undefined,
        payType: payType || undefined,
      };
      return params;
    },
    resetSearch() {
      this.$refs.form.resetFields();
      this.$nextTick(() => {
        this.page = 1;
        this.getDataList();
      });
    },
    getPlotAllList() {
      getPlotAllList().then((res) => {
        this.plotList = res.data || [];
      });
    },
  },
};
</script>
<style scoped lang="scss">
.order {
  display: flex;
  flex-direction: column;
  color: #333;

  .order-1 {
    padding: 11px 0px;
    display: flex;

    .text {
      color: #606266;
      font-weight: 600;
      text-align: right;
      padding-right: 12px;
      width: 100px;
    }
  }
}

.tipjd-box {
  display: flex;
}

.tipjd-box .jt-item {
  width: 25%;
  text-align: center;
}

.tipjd-box .jt-item p .el-icon-success {
  font-size: 40px;
  margin-bottom: 10px;
  color: #008000;
}
</style>
