<template>
  <div class="main">
    <div class="content-box">
      <el-select
        clearable
        style="width:200px;"
        v-model="dataStatus"
        placeholder="是否生效"
      >
        <el-option label="正常" :value="0"></el-option>
        <el-option label="禁用" :value="1"></el-option>
      </el-select>
      <el-select
        clearable
        style="width:200px;margin-left:20px;"
        v-model="showStatus"
        placeholder="是否显示"
      >
        <el-option label="不展示" :value="0"></el-option>
        <el-option label="展示" :value="1"></el-option>
      </el-select>
      <el-button
        @click="searchData"
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-search"
        >搜索</el-button
      >
      <el-button
        @click="addHandler"
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-plus"
        >新增</el-button
      >
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="促销名称  "
          align="center"
          min-width="120"
        ></el-table-column>

        <el-table-column
          prop="totalAmount"
          align="center"
          label="促销满足金额 "
          min-width="120"
        ></el-table-column>

        <el-table-column
          prop="giftAmount"
          align="center"
          label="奖励金额 "
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="showStatus"
          label="是否展示"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.showStatus"
              active-value="1"
              @change="changeShowStatusHandler($event, scope.row)"
              inactive-value="0"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column
          prop="dataStatus"
          label="数据状态"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.dataStatus"
              active-value="0"
              @change="changeStateHandler($event, scope.row)"
              inactive-value="1"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="createBy"
          label="创建人"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="120"
        ></el-table-column>

        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <!-- <el-link @click="getUseRecodsHandler(scope.row)">使用记录</el-link> -->
              <el-button type="danger" @click="deleteHandler(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="100px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="促销名称 " prop="name">
          <el-input
            v-model="formData.name"
            style="width:300px;"
            placeholder="请输入促销名称"
            clearable
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="满足金额" prop="name">
          <el-input
            type="number"
            maxlength="5"
            v-model="formData.totalAmount"
            style="width:300px;"
            placeholder="请输入满足金额"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="奖励金额" prop="name">
          <el-input
            type="number"
            maxlength="5"
            v-model="formData.giftAmount"
            style="width:300px;"
            placeholder="请输入奖励金额"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="数据状态" prop="name">
          <el-select
            style="width:200px"
            v-model="formData.dataStatus"
            placeholder="请选择"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="nav-form-item" label="是否显示" prop="name">
          <el-select
            style="width:200px"
            v-model="formData.showStatus"
            placeholder="请选择"
          >
            <el-option label="不显示" value="0"></el-option>
            <el-option label="显示" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button :disabled="isDisable" type="primary" @click="confimHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPromotionList,
  delPromotion,
  addPromotion,
  updatePromotion,
} from "@/api/promotion";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      isDisable: false,
      dataStatus: null,
      showStatus: null,
      dialogVisible: false,
      operatorId: "",
      date: "",
      id: "",
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      formData: {
        dataStatus: 0,
        giftAmount: null,
        name: "",
        showStatus: 0,
        totalAmount: null,
      },
      total: 0,

      options: [],
      formRule: {
        name: [{ required: true, message: "请输入必填项", trigger: "blur" }],
      },
      popupModalTitle: "新增",
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    showStatusFormatter(row) {
      if (row.showStatus == 0) {
        return "不显示";
      } else if (row.showStatus == 1) {
        return "显示";
      }
    },
    dataStatusFormatter(row) {
      if (row.dataStatus == 0) {
        return "正常";
      } else if (row.dataStatus == 1) {
        return "禁用";
      } else if (row.dataStatus == 2) {
        return "删除";
      }
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        dataStatus: this.dataStatus,
        showStatus: this.showStatus,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getPromotionList(param).then((res) => {
        if (res.data && res.data.records.length >= 0) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.dataStatus = "0";
      this.formData.giftAmount = null;
      this.formData.name = "";
      this.formData.showStatus = "0";
      this.formData.totalAmount = null;
      this.popupModalTitle = "新增";
      this.dialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addCard();
          } else {
            this.editCard();
          }
        }
      });
    },
    deleteHandler(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delPromotion(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.id = row.id;
      this.formData.dataStatus = row.dataStatus;
      this.formData.giftAmount = row.giftAmount;
      this.formData.name = row.name;
      this.formData.showStatus = row.showStatus;
      this.formData.totalAmount = row.totalAmount;
      this.popupModalTitle = "编辑";
      this.dialogVisible = true;
    },
    editCard() {
      this.isDisable = true;
      updatePromotion(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.isDisable = false;
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    addCard() {
      this.isDisable = true;
      addPromotion(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.isDisable = false;
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    getUseRecodsHandler(row) {
      this.$router.push({
        path: "card/useRecods",
        query: {
          cardNo: row.cardNo,
        },
      });
    },
    changeStateHandler($event, row) {
      let param = {
        dataStatus: $event,
        id: row.id,
      };
      updatePromotion(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    changeShowStatusHandler($event, row) {
      let param = {
        showStatus: $event,
        id: row.id,
      };
      updatePromotion(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
  },
};
</script>
<style scoped>
.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}
</style>
