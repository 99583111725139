<template>
  <div class="main">
    <div class="main-layout">
      <div class="sidebar">
        <el-input placeholder="运营商/线路名称" v-model="filterInput">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-tree
          :data="treeData"
          node-key="id"
          style="width: 100%;flex:1;overflow: auto;"
          :props="treeProps"
          default-expand-all
          :expand-on-click-node="false"
          :highlight-current="highlightCurrent"
          @node-click="handleNodeClick"
          :filter-node-method="filterTree"
          ref="treeRef"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="custom-tree-label" v-overflow-tooltip>{{
              node.label
            }}</span>
            <span
              class="mini-tag"
              v-if="data.children.length > 0"
              v-overflow-tooltip
              >{{ data.children.length }}</span
            >
          </span>
        </el-tree>
      </div>
      <div class="main-content">
        <div class="data-box">
          <div class="data-box-item ">
            <img class="icon" src="@/assets/order/intelligentRegulation.svg" />
            <div class="flex-y-1 gap-8">
              <div class="box-item-label">
                {{ panelData.dispatchLines }}
              </div>
              <div
                class="flex-x gap-4 box-item-desc"
                style="align-items: center;"
              >
                进入智能化调控支线

                <el-tooltip class="item" effect="dark" placement="top">
                  <img
                    class="mini-icon"
                    src="@/assets/order/question-circle-outlined.svg"
                  />

                  <div slot="content">
                    指站点/电表的下级汇总的实<br />时电流触发自动调控条件，进<br />入智能调控阶段
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="data-box-item ">
            <img class="icon" src="@/assets/order/电表数量.svg" />
            <div class="flex-y-1 gap-8">
              <div class="box-item-label">{{ panelData.ammeters }}</div>
              <div class="flex-x gap-4 box-item-desc">
                电表数量
              </div>
            </div>
          </div>
          <div class="data-box-item ">
            <img class="icon" src="@/assets/order/交直流枪数.svg" />
            <div class="flex-y-1 gap-8">
              <div class="box-item-label">
                {{ panelData.connectorDcs }}/{{ panelData.connectorAcs }}
              </div>
              <div class="flex-x gap-4 box-item-desc">
                直流枪数/交流枪数
              </div>
            </div>
          </div>
          <div class="data-box-item ">
            <img class="icon" src="@/assets/order/调控枪数.svg" />
            <div class="flex-y-1 gap-8">
              <div class="box-item-label">
                {{ panelData.dispatchConnectors }}/{{ panelData.connectors }}
              </div>
              <div class="flex-x gap-4 box-item-desc">
                调控枪数/总枪数
              </div>
            </div>
          </div>
        </div>
        <div class="chart-box">
          <div class="filter-bar">
            <el-radio-group v-model="chartType">
              <el-radio-button label="1">电流趋势</el-radio-button>
              <el-radio-button label="2">功率趋势</el-radio-button>
            </el-radio-group>
            <div class="filter-side-right">
              <div class="single-selector" @click="handleDateSelect">
                <div
                  v-for="item in dateRange"
                  class="single-selector-label"
                  :class="{ 'is-active': +dateSelect === item.value }"
                  :data-index="item.value"
                >
                  {{ item.label }}
                </div>
              </div>
              <el-date-picker
                v-model="chartDateRange"
                style="width: 250px;"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleDateRangeChange"
              >
              </el-date-picker>
            </div>
          </div>
          <el-empty
            v-if="isChartNull"
            style="height: 100%;width: 100%;"
            image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
            :image-size="48"
          ></el-empty>
          <v-chart v-else :option="chartOption" autoresize />
        </div>
        <div style="width: 100%;height: 100%;min-height:0;">
          <el-table
            ref="tableTreeRef"
            v-loading="tableLoading"
            :data="tableData"
            max-height="100%"
            row-key="id"
            :key="currentOperId"
            default-expand-all
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :header-cell-style="{
              background: '#f7f8fa',
              color: '#000000',
              fontSize: '14px',
              padding: '7px 8px',
            }"
          >
            <el-table-column prop="nodeName" label="设备名称" min-width="420">
              <template slot-scope="scope">
                <div class="node-name">
                  <span
                    >{{ scope.row.nodeName
                    }}{{
                      scope.row.nodeCode && `(${scope.row.nodeCode})`
                    }}</span
                  >
                  <LightTag
                    size="mini"
                    type="tip"
                    v-if="refPhaseFormat(scope.row)"
                    >{{ refPhaseFormat(scope.row) }}</LightTag
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="nodeType" label="设备类型" width="100">
              <template slot-scope="{ row }">
                <PlainTag
                  :text="nodeTypeFormat(row.nodeType)"
                  :color="nodeTypeColorFormat(row.nodeType)"
                  :bgColor="nodeTypeBgColorFormat(row.nodeType)"
                ></PlainTag>
              </template>
            </el-table-column>
            <el-table-column
              prop="currentCeilStr"
              label="电流上限 (A)"
              width="120"
              :formatter="
                (row) => {
                  return row.nodeType === 4 ? '-' : row.currentCeilStr;
                }
              "
            >
            </el-table-column>
            <el-table-column
              prop="ctCurrentStr"
              label="实时电流 (A)"
              width="120"
            >
              <template slot-scope="scope">
                <span
                  :style="{ color: ctPowerColorFormater(scope.row) }"
                  v-if="[1, 2, 4].includes(scope.row.nodeType)"
                  >{{ ctCurrentFormater(scope.row) }}</span
                >
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="ctPowerStr"
              label="实时功率 (kW)"
              width="120"
            >
              <template slot-scope="scope">
                <span
                  :style="{ color: ctPowerColorFormater(scope.row) }"
                  v-if="[1, 2, 4].includes(scope.row.nodeType)"
                  >{{ ctPowerFormater(scope.row) }}</span
                >
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="surplusPowerStr"
              label="剩余功率 (kW)"
              width="120"
            >
              <template slot-scope="scope">
                <span v-if="[1, 2].includes(scope.row.nodeType)">{{
                  scope.row.surplusPowerStr
                }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="dispatchPolicy" label="充电调控" width="140">
              <template slot-scope="scope">
                <div>
                  <span>{{ controlStatusFormat(scope.row) }}</span>
                  <span>
                    <PlainTag
                      color="#4E5969"
                      bgColor="rgba(29, 33, 41, 0.06)"
                      v-if="isDispatchPolicyVisible(scope.row)"
                      >{{
                        dispatchingStrategyFormat(scope.row.dispatchPolicy)
                      }}</PlainTag
                    ></span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="stat" label="通讯状态" width="120">
              <template slot-scope="scope">
                <span :style="{ color: scope.row.stat === 2 ? '' : '#DF3737' }">
                  {{ statFormat(scope.row.stat) }}
                </span>
                <PlainTag
                  :color="connectorStatColorFormat(scope.row.connectorStat)"
                  :bgColor="connectorStatBgColorFormat(scope.row.connectorStat)"
                  v-if="isConnectorStatVisible(scope.row)"
                  >{{ connectorStatFormat(scope.row.connectorStat) }}</PlainTag
                >
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="170">
              <template slot-scope="scope">
                <MulOperBar :actions="getBtnActions(scope.row)"></MulOperBar>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <meterDetailsDrawer ref="meterDetailsDrawerRef"></meterDetailsDrawer>
    <autoControlDialog
      ref="autoControlDialogRef"
      @confirm="handleControlConfirm"
    ></autoControlDialog>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  nextTick,
  watch,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import VChart from "vue-echarts";
import PlainTag from "@/components/PlainTag/index.vue";
import MulOperBar from "@/components/MulOperBar/index.vue";
import meterDetailsDrawer from "./meterDetailsDrawer.vue";
import autoControlDialog from "./autoControlDialog.vue";
import { getName } from "@/utils/auth";
import dayjs from "dayjs";
import { getLineMonitor } from "@/api/power";
import {
  getNodeTreeList,
  updateLineNodeDispatchPolicy,
  getLineStats,
  getLineTree,
} from "@/api/line";
import {
  NODE_TYPE,
  CHARGE_TYPE,
  DISPATCHING_STRATEGY,
  DEVICE_STATUS,
  CONNECTOR_STATUS,
  findLabelByValue,
} from "@/utils/const";
import LightTag from "@/components/LightTag/index.vue";
import Decimal from "decimal.js";
import { Message } from "element-ui";

const chartType = ref("1");

watch(chartType, (value) => {
  getAmmeterMonitorChartData(currentOperId.value);
});
let treeProps = ref({
  children: "children",
  label: "name",
});
const highlightCurrent = ref(false);
const treeRef = ref();

const meterDetailsDrawerRef = ref(null);
const autoControlDialogRef = ref(null);
const chartDateRange = ref([
  dayjs()
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss"),
  dayjs()
    .endOf("day")
    .format("YYYY-MM-DD HH:mm:ss"),
]);
const currentOperId = ref(null);

const nodeTypeFormat = (value) => {
  return findLabelByValue(NODE_TYPE, value);
};
const nodeTypeColorFormat = (value) => {
  return findLabelByValue(NODE_TYPE, value, "color");
};
const nodeTypeBgColorFormat = (value) => {
  return findLabelByValue(NODE_TYPE, value, "bgColor");
};
const dispatchingStrategyFormat = (value) => {
  return findLabelByValue(DISPATCHING_STRATEGY, value);
};
const chargeTypeFormat = (value) => {
  return findLabelByValue(CHARGE_TYPE, value);
};
const statFormat = (value) => {
  return findLabelByValue(DEVICE_STATUS, value);
};
const connectorStatFormat = (value) => {
  return findLabelByValue(CONNECTOR_STATUS, value);
};
const connectorStatColorFormat = (value) => {
  return findLabelByValue(CONNECTOR_STATUS, value, "color");
};
const connectorStatBgColorFormat = (value) => {
  return findLabelByValue(CONNECTOR_STATUS, value, "bgColor");
};
const isDispatchPolicyVisible = (value) => {
  /**
   * dispatchPolicy 调度策略(0:关闭 1:时间 2:均分 3:智能)
   * connectorStat	枪口状态(0:离线 1:故障 2:空闲 3:充电 4:插枪)
   */
  return (
    dispatchingStrategyFormat(value.dispatchPolicy) &&
    [4].includes(value.nodeType) &&
    value.connectorStat == 3 &&
    value._parent.isDispatch
  );
};

//  实时电流展示
const ctCurrentFormater = (row) => {
  if ([1, 2].includes(row.nodeType)) {
    return "-";
  } else {
    return row.ctCurrentStr || "-";
  }
};
//  实时功率展示
const ctPowerFormater = (row) => {
  if ([1, 2].includes(row.nodeType)) {
    return "-";
  } else {
    return row.ctPowerStr || "-";
  }
};

const isConnectorStatVisible = (row) => {
  return (
    connectorStatFormat(row.connectorStat) &&
    row.nodeType == 4 &&
    row.connectorStat !== 0
  );
};

// 调控字段 1自动调控 0正常充电
const controlStatusFormat = (value) => {
  if ([1, 2, 3].includes(value.nodeType)) {
    return "-";
  }
  // 此处逻辑为旺仔zhuzhiwang@lidian-icharging.com定义
  // 充电中
  if (value.connectorStat === 3) {
    // 判断是否调度
    return value._parent.isDispatch ? "自动调控" : "正常充电";
  } else {
    return "-";
  }
};
const refPhaseFormat = (row) => {
  let arr = [];
  // 判断交流直流
  if (row.pileType !== undefined && row.pileType !== null) {
    arr.push(chargeTypeFormat(row.pileType));
  }
  // 判断相线
  if (row.refPhase) {
    arr.push(row.refPhase + "相");
  }

  return arr.join("/");
};

const treeData = ref([]);
// 获取运营商权限(是否运营商)
let isOperator = computed(() => {
  try {
    let name = JSON.parse(getName());
    return name.userInfo.isOperator === 1;
  } catch (error) {}
});
const findFirstLeafNode = (tree) => {
  for (const node of tree) {
    if (node.children.length === 0) {
      // 如果当前节点没有子节点，说明这是一个叶子节点
      return node;
    } else {
      // 如果当前节点有子节点，递归查找子节点中的首个叶子节点
      const leaf = findFirstLeafNode(node.children);
      if (leaf) {
        return leaf;
      }
    }
  }
};
// 获取运营商树
function getTreeData() {
  getLineTree().then((res) => {
    if (!isOperator.value) {
      treeData.value = [
        {
          children: res.data,
          id: 0,
          name: "全部运营商",
        },
      ];
    } else {
      treeData.value = res.data;
    }
    highlightCurrent.value = true;
    let node = findFirstLeafNode(res.data);

    nextTick(() => {
      handleNodeClick(node, {
        isLeaf: true,
      });
    });
  });
}
getTreeData();

const panelData = ref({});
// 获取面板数据
function getPanelData(id) {
  getLineStats({ lineId: id }).then((res) => {
    panelData.value = res.data;
  });
}

let colorStatObj = {
  1: "",
  2: "#F87700",
  3: "#DF3737",
};
const ctPowerColorFormater = (row) => {
  return colorStatObj[(row?._parent?._parent?.colorStat)] || "";
};

function handleNodeClick(data, node) {
  if (node.isLeaf) {
    highlightCurrent.value = true;
    treeRef.value.setCurrentKey(data.id); // 假设每个节点都有一个唯一的id
    currentOperId.value = data.id;
    setNodeTreeWalker();
  } else {
    highlightCurrent.value = false;
    // treeRef.clearCurrentKey();
  }
}
// 树轮询器 setInterval

let getNodeTreeListListInterval = null;
function setNodeTreeWalker() {
  if (getNodeTreeListListInterval) {
    clearInterval(getNodeTreeListListInterval);
  }
  handlePageDataLoad();
  getNodeTreeListListInterval = setInterval(() => {
    handlePageDataLoad();
  }, 1000 * 15);
}

// 轮询器销毁
onUnmounted(() => {
  clearInterval(getNodeTreeListListInterval);
});

onActivated(() => {
  setNodeTreeWalker();
});

onDeactivated(() => {
  clearInterval(getNodeTreeListListInterval);
});

let dateSelect = ref(0);
let dateRange = ref([
  {
    label: "今天",
    value: 0,
  },
  {
    label: "近7天",
    value: 6,
  },
  {
    label: "近30天",
    value: 29,
  },
]);
function handleDateSelect(e) {
  dateSelect.value = e.target.dataset.index;

  if (dateSelect.value) {
    chartDateRange.value = [
      dayjs()
        .subtract(e.target.dataset.index, "day")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      dayjs()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
    ];
    getAmmeterMonitorChartData(currentOperId.value);
  }
}

function handleDateRangeChange() {
  getAmmeterMonitorChartData(currentOperId.value);
}

let filterInput = ref("");
function filterTree(value, data, node) {
  if (!value) return true;
  if (data.name.indexOf(value) > -1) return true;
  if (node.parent.parent) {
    return node.parent.visible;
  }

  return false;
}
watch(filterInput, (val) => {
  treeRef.value.filter(val);
});

// 图表展示
let chartOption = ref({});
let isChartNull = ref(true);
let waveIconSvgPath =
  "path://M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z";
let getChartOption = (data) => {
  let unit = chartType.value === "1" ? "A" : "kW";
  let series = [];
  let timePoints = [];
  let legendSelected = {};
  data.forEach(function(item, index) {
    legendSelected[item.name] = index <= 8;

    var seriesItem = {
      name: item.name,
      type: "line",
      yAxisIndex: 0,
      zlevel: 20,
      smooth: true,
      symbolSize: 8,
      markLine: {
        data: [
          {
            yAxis: item.currentCeil,
          },
        ],
        lineStyle: {
          color: "#86909C",
        },
        symbol: "none",
        label: {
          position: "insideStartBottom",
          fontSize: 10,
          color: "#86909C",
          formatter: "{a}功率上限",
        },
        silent: true,
      },
      showSymbol: false,

      data: item.data.map(function(d) {
        if (!timePoints.includes(d.createTime)) {
          timePoints.push(d.createTime);
        }
        return chartType.value === "1" ? d.current : d.power;
      }),
    };
    timePoints.sort(); // 确保时间点按顺序
    series.push(seriesItem);
  });
  return {
    color: [
      "#18B9DF",
      "#2CE0DD",
      "#F7A70D",
      "#FC5926",
      "#F6CF32",
      "#3B92EF",
      "#3BCBF3",
      "#52EEB9",
      "#B5DBF4",
      "#CAB6FF",
      "#DDF1AB",
      "#FFBADC",
      "#E9CFFE",
      "#F5E597",
    ],
    legend: {
      type: "scroll",
      bottom: 16,
      icon: "circle",
      itemWidth: 10,
      textStyle: {
        color: "rgba(78, 89, 105, 1)",
      },
      icon: waveIconSvgPath,
      selected: legendSelected,
    },
    tooltip: {
      trigger: "axis",
      icon: waveIconSvgPath,
      formatter(params) {
        let htmlText = "";
        params.forEach((item, index) => {
          if (index === 0) {
            htmlText += `<p style="color: var(--Text-5-, var(--PC-, #1D2129));font-size: 12px;">${
              item.axisValue
            }</p>`;
          }
          htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between">
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${item.value ||
            "-"}${unit}</span></p>`;
        });
        return htmlText;
      },
    },
    grid: {
      top: 30,
      right: 30,
      left: 46,
      bottom: 84,
      containLabel: true,
    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 60,
        end: 100,
        bottom: 45,
        height: 16,
        xAxisIndex: [0, 1],
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisLine: {
        onZero: true,
        lineStyle: {
          color: "#E5E6EB",
        },
      },
      data: timePoints,
      axisLabel: {
        color: "#86909C",
        formatter(value) {
          return dayjs(value).format("HH:mm:ss");
        },
      },
      axisTick: {
        show: false,
      },
    },
    axisPointer: {
      lineStyle: {
        color: "#C9CDD4",
        type: "solid",
      },
    },
    yAxis: [
      {
        name: `单位 (${unit})`,
        type: "value",
        nameTextStyle: {
          color: "#86909C",
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: "#86909C",
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: ["#E5E6EB"],
            opacity: 0.5,
          },
        },
      },
    ],
    series,
    // series: [
    //   {
    //     name: "总电流",
    //     type: "line",
    //     yAxisIndex: 0,
    //     zlevel: 20,
    //     smooth: true,
    //     symbolSize: 8,
    //     encode: {
    //       x: "createTime",
    //       y: "chargePowerStr",
    //     },
    //     markLine: {
    //       data: [
    //         {
    //           type: "average",
    //         },
    //       ],
    //       lineStyle: {
    //         color: "#86909C",
    //       },
    //       symbol: "none",
    //       label: {
    //         position: "insideStartBottom",
    //         fontSize: 10,
    //         color: "#86909C",
    //       },
    //       silent: true,
    //     },
    //     showSymbol: false,
    //   },
    //   {
    //     name: "电表1号",
    //     type: "line",
    //     yAxisIndex: 0,
    //     zlevel: 20,
    //     showSymbol: false,
    //     symbolSize: 8,
    //     encode: {
    //       x: "createTime",
    //       y: "soc",
    //     },
    //   },
    // ],
  };
};
function getAmmeterMonitorChartData(lineId) {
  getLineMonitor({
    beginTime: chartDateRange.value[0],
    endTime: chartDateRange.value[1],
    lineId,
  }).then((res) => {
    isChartNull.value = res.data.length === 0;
    chartOption.value = getChartOption(res.data || []);
  });
}

// getAmmeterMonitorChartData();
// table
const tableData = ref([]);
const tableTreeRef = ref(null);

const tableLoading = ref(false);
function addParentProperty(obj, parent = null) {
  obj._parent = parent;

  if (obj.children && Array.isArray(obj.children)) {
    obj.children.forEach((child) => {
      addParentProperty(child, obj); // 递归调用，传递当前对象作为父对象
    });
  }
}
function getNodeTreeListList(id) {
  tableLoading.value = true;
  getNodeTreeList({
    lineId: id,
  })
    .then((res) => {
      tableLoading.value = false;
      tableData.value = res.data;
      addParentProperty(tableData.value[0]);
    })
    .catch((e) => {
      tableLoading.value = false;
    });
}

function getBtnActions(row) {
  return [
    {
      text: "详情",
      type: "text",
      // 电表、终端支持查看详情
      visible: [2, 4].includes(row.nodeType),
      click: () => {
        meterDetailsDrawerRef.value.open(row);
      },
    },
    {
      text: "自动调控设置",
      type: "text",
      // 电表、站点支持自动调控设置
      visible: [2, 1].includes(row.nodeType),
      click: () => {
        autoControlDialogRef.value.open(row);
      },
    },
  ];
}

// 确认
function handleControlConfirm(e) {
  updateLineNodeDispatchPolicy({
    id: e.id,
    dispatchPolicy: e.dispatchPolicy,
  }).then((res) => {
    if (res.message.status == 200) {
      getNodeTreeListList(currentOperId.value);
      autoControlDialogRef.value.close();
      Message.success("操作成功");
    } else {
      Message.error(res.message);
    }
  });
}

function handlePageDataLoad() {
  getNodeTreeListList(currentOperId.value);
  getPanelData(currentOperId.value);
  getAmmeterMonitorChartData(currentOperId.value);
}
</script>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 8px;
  height: 100%;
  min-height: 821px;
  .sidebar {
    display: flex;
    min-width: 220px;
    padding: 16px;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    gap: var(--Space-Padding-paddingSM, 12px);
    align-self: stretch;
    border-right: 1px solid var(--PC--, #e5e6eb);
    ::v-deep .el-tree-node__content {
      height: 36px;
    }
    ::v-deep
      .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      background-color: rgba(5, 124, 152, 0.04);
      color: rgba(5, 124, 152, 1);
    }
    ::v-deep .el-tree-node__expand-icon.is-leaf {
      padding: 0px;
      &::before {
        width: 6px;
        display: block;
      }
    }
  }
  .main-content {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    min-width: 0;
    .data-box {
      display: flex;
      width: 100%;
      padding: 16px;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid var(--PC--, #e5e6eb);
    }
    .data-box-item {
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      .icon {
        width: 64px;
        height: 64px;
      }
      .mini-icon {
        width: var(--Space-Padding-paddingSM, 12px);
        height: var(--Space-Padding-paddingSM, 12px);
      }
      .box-item-label {
        color: var(--PC-, #1d2129);
        font-family: D-DIN-PRO;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .box-item-desc {
        color: var(--PC-, #4e5969);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: var(--current-color);
      background-color: #fff;
      border-color: var(--current-color);
    }
    ::v-deep .el-radio-button__inner:hover {
      color: var(--current-color);
    }
    ::v-deep .el-radio-button__inner {
      padding: 6px 16px;
      color: var(--PC-, #1d2129);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden; //将横向滚动条隐藏
      ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(216, 39, 39, 0.3);
        background-color: rgb(0, 12, 46); /*滚动条的背景颜色*/
      }
    }
    ::v-deep .el-table__fixed,
    .el-table__fixed-right {
      box-shadow: 0px -7px 16px rgba(0, 0, 0, 0.12);
    }
    ::v-deep .el-table::before {
      display: none;
    }
    ::v-deep .el-table th.el-table__cell > .cell {
      padding-left: 0;
      padding-right: 0;
    }
    ::v-deep .el-table__expand-icon {
      .el-icon-arrow-right:before {
        content: "\e791";
        color: rgba(134, 144, 156, 1);
      }
    }
    ::v-deep .el-button--text {
      font-weight: 400;
      font-size: 14px;
      color: var(--current-color, #057c98);
    }
    ::v-deep .el-table .cell {
      font-size: 14px;
    }
    .chart-box {
      display: flex;
      height: 400px;
      min-height: 400px;
      min-width: 642px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--PC--, #e5e6eb);
      background: var(--PC-, #fff);
    }
    .filter-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      width: 100%;
      padding: 16px 16px 0 16px;
    }
    .filter-side-right {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .single-selector {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      .single-selector-label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
        &.is-active {
          color: var(--current-color);
        }
      }
    }
  }
}
.custom-tree-node {
  display: flex;
  align-items: center;
  gap: 2px;
  overflow: hidden;
  flex: 1 0 0;
  width: 100%;
}
.custom-tree-label {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.mini-tag {
  display: flex;
  height: 14px;
  min-width: 24px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: rgba(29, 33, 41, 0.06);
  overflow: hidden;
  color: var(--PC-, #4e5969);
  text-align: center;
  text-overflow: ellipsis;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.gap-8 {
  gap: 8px;
}
.gap-4 {
  gap: 4px;
}
.flex-y-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}
.flex-x {
  display: flex;
  align-items: center;
}
.node-name {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--PC-, #1d2129);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  flex: 1 0 0;
}
</style>
