<template>
  <el-dialog
    title="自动调控策略设置"
    :visible.sync="visible"
    width="750px"
    :close-on-click-modal="false"
    :before-close="handleBeforeClose"
    @close="onClose"
  >
    <div class="content-layout">
      <div class="meter-card">
        <div class="meter-card-header">
          <div class="banner-title">
            <img
              src="@/assets/order/meter.svg"
              style="width: 24px; height: 24px"
              alt=""
            />
            <span>{{ currentRow.nodeName }}</span>
          </div>
          <div v-if="currentRow.nodeType === 2" class="banner-desc">
            编号：{{ currentRow.nodeCode }}
          </div>
        </div>
        <div class="meter-card-footer">
          <img
            src="@/assets/order/info-circle-outlined.svg"
            style="width: 14px; height: 14px"
            alt=""
          />
          当实时电流≥(线路电流上限*80%)，将进入自动调控阶段，实现有序充电，并控制输出电流不超设定上限
        </div>
      </div>
      <div class="meter-card-content">
        <div
          class="card-selector"
          :class="{
            'is-active': activeIndex === 1,
          }"
          @click="handleClick(1)"
        >
          <div>时间顺序</div>
          <div class="card-selector-desc">
            先启动充电的车辆优先充电，若电流不足时，后续的车辆需排队等待
          </div>
        </div>
        <div
          class="card-selector"
          :class="{
            'is-active': activeIndex === 2,
          }"
          @click="handleClick(2)"
        >
          <div>均分</div>
          <div class="card-selector-desc">
            充电的车辆平均分配电流，若均分到最低电流值时，后续的车辆需排队等待
          </div>
        </div>
        <div
          class="card-selector is-disabled"
          :class="{
            'is-active': activeIndex === 3,
          }"
        >
          <div>智能算法</div>
          <div class="card-selector-desc">
            充电的车辆按照智能算法自动调控（往帕累托最优方向调控）
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleBeforeClose(() => (visible = false))">
        取 消
      </el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script setup>
import { ref, defineExpose } from "vue";

const visible = ref(false);
const activeIndex = ref(1);
const currentRow = ref({});

function open(row) {
  currentRow.value = row;
  activeIndex.value = row.dispatchPolicy || 1;
  visible.value = true;
}

function close() {
  visible.value = false;
}

function handleBeforeClose(done) {
  done();
}
function handleClick(i) {
  activeIndex.value = i;
}

function onClose() {
  activeIndex.value = 1;
}

defineExpose({ open, close });
const emit = defineEmits(["confirm"]);
function handleConfirm() {
  emit("confirm", { ...currentRow.value, dispatchPolicy: activeIndex.value });
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  box-shadow: 0px 1px 0px 0px var(--PC--, #e5e6eb);
}
::v-deep .el-dialog__body {
  display: flex;
  padding: 0;
  flex-direction: column;
  gap: 16px;
}
::v-deep .el-dialog__footer {
  padding: 11px 24px;
  box-shadow: 0px -1px 0px 0px var(--PC--, #e5e6eb);
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  ::v-deep .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }
}
.content-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 24px var(--Space-Padding-paddingLG, 24px) 40px
    var(--Space-Padding-paddingLG, 24px);
  align-self: stretch;
  .meter-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(91deg, #ebf5f7 0.07%, #f5f2f0 100%);
    .meter-card-header {
      display: flex;
      padding: var(--Space-Padding-paddingMD, 20px)
        var(--Space-Padding-paddingSM, 12px) 16px
        var(--Space-Padding-paddingSM, 12px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .banner-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        color: var(--PC-, #1d2129);
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .banner-desc {
        color: var(--PC-, #4e5969);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .meter-card-footer {
      display: flex;
      padding: 8px var(--Space-Padding-paddingSM, 12px);
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 0px 0px 8px 8px;
      background: rgba(29, 33, 41, 0.04);
      color: var(--PC-, #4e5969);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .meter-card-content {
    display: flex;
    align-items: flex-start;
    gap: var(--Space-Padding-paddingSM, 12px);
    align-self: stretch;
    .card-selector {
      position: relative;
      display: flex;
      padding: var(--Space-Padding-paddingMD, 20px) 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--PC--, #e5e6eb);
      background: var(--PC-, #fff);
      color: var(--PC-, #4e5969);
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      overflow: hidden;
      cursor: pointer;
      &.is-active {
        border-radius: 8px;
        border: 1px solid var(--PC-, #057c98);
        background: var(--PC-, #fff);
        &:after {
          content: "";
          position: absolute;
          right: -1px;
          top: -1px;
          width: 22px;
          height: 15px;
          background-image: url("../../assets/order/modal-data-entry-radio-light.svg");
        }
      }
      &.is-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      .card-selector-subscript {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 22px;
        height: 15px;
      }
    }
    .card-selector-desc {
      color: var(--PC-, #86909c);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
</style>
