<template>
  <el-dialog
    title="退款"
    :visible.sync="dialogVisibleRefund"
    width="450px"
    :close-on-click-modal="false"
    :before-close="handleBeforeClose"
  >
    <div class="alert">
      <div class="alert-icon-container">
        <img class="alert-icon" src="@/assets/order/info.svg" />
      </div>
      <div class="alert-text">
        退款功能仅作为退款维权业务的补充功能，请谨慎操作！确认后退款金额将原路退回每个订单最多退款一次）
      </div>
    </div>
    <div class="order">
      <div class="order-info">
        <div class="order-info-item">
          <div class="label">订单号</div>
          <div class="value" v-overflow-tooltip>{{ data.orderNumber }}</div>
        </div>
        <div class="order-info-item">
          <div class="label">车主手机</div>
          <div class="value">{{ data.userTelephoneNo }}</div>
        </div>
        <div class="order-info-item">
          <div class="label">订单金额 (元)</div>
          <div class="value">{{ data.realAmountStr }}</div>
        </div>
        <div class="order-info-item">
          <div class="label">实付金额 (元)</div>
          <div class="value">{{ data.amountStr }}</div>
        </div>
      </div>
      <el-form
        ref="formRefund"
        :model="formDataRefund"
        label-suffix=""
        label-width="160px"
        :rules="rules"
        size="mini"
        label-position="top"
        style="width: 100%;"
      >
        <el-form-item
          class="nav-form-item"
          label="退款金额（元）"
          prop="refundAmount"
        >
          <el-input
            maxlength="6"
            type="text"
            v-model="formDataRefund.refundAmount"
            style="width:100%;"
            placeholder="请输入退款金额"
            clearable
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="退款原因" prop="remark">
          <el-input
            maxlength="30"
            type="textarea"
            :rows="3"
            autocomplete="off"
            v-model="formDataRefund.remark"
            style="width:100%;"
            placeholder="请输入退款原因"
            clearable
            show-word-limit
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="登录密码" prop="password">
          <el-input
            maxlength="50"
            type="password"
            autocomplete="new-password"
            v-model="formDataRefund.password"
            style="width:100%;"
            placeholder="请输入账号登录密码验证本人操作"
            clearable
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleBeforeClose(() => (dialogVisibleRefund = false))"
        >取 消</el-button
      >
      <el-button type="primary" @click="confimRefundHandler">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialogVisibleRefund: false,
      formDataRefund: {
        orderId: "",
        remark: "",
        password: "",
        refundAmount: "",
      },
    };
  },
  computed: {
    rules() {
      return {
        refundAmount: [
          {
            required: true,
            message: "请输入退款金额",
            trigger: "blur",
          },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: "请输入正确的金额",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              let val = value * 1;
              if (val > this.data.amountStr * 1) {
                callback(new Error("退款金额不能大于实付金额"));
              } else if (val <= 0 || val > this.data.amountStr * 1) {
                callback(new Error("退款金额应在0到实付金额之间哦"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入退款原因",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    open() {
      this.dialogVisibleRefund = true;
    },
    close() {
      this.dialogVisibleRefund = false;
    },
    confimRefundHandler() {
      this.$refs.formRefund.validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            orderNumber: this.data.orderNumber,
            password: this.formDataRefund.password,
            refundAmount: this.formDataRefund.refundAmount,
            refundDesc: this.formDataRefund.remark,
          });
        }
      });
    },
    handleBeforeClose(done) {
      this.$refs.formRefund.resetFields();
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  display: flex;
  padding: 9px var(--Space-Padding-paddingSM, 12px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #ffe58f;
  background: #fffbe6;
  gap: 4px;
  flex: 1 0 0;
}
.alert-icon-container {
  display: flex;
  width: 22px;
  height: 22px;
  padding: 3px;
  justify-content: center;
  align-items: center;
}
.alert-icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.alert-text {
  color: rgba(0, 0, 0, 0.65);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
::v-deep .el-dialog__header {
  box-shadow: 0px 1px 0px 0px var(--PC--, #e5e6eb);
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 16px var(--Space-Padding-paddingLG, 24px) 0px
    var(--Space-Padding-paddingLG, 24px);
  gap: 16px;
}
.order {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  ::v-deep .el-form--label-top .el-form-item__label {
    padding: 0 0 4px;
    font-weight: 500;
  }
}
.order-info {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
  flex-wrap: wrap;
  .order-info-item {
    display: flex;
    height: var(--Space-Padding-paddingMD, 20px);
    min-width: 170px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    .label {
      color: var(--PC-, #86909c);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .value {
      flex: 1 0 0;
      color: var(--PC-, #1d2129);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-input__count {
  color: var(--PC-, #c9cdd4);
  text-align: right;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
