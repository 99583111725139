<template>
  <el-drawer
    :title="drawerTitle"
    direction="rtl"
    :visible.sync="visible"
    size="600px"
    style="border-radius: 8px 0px 0px 8px;"
    :before-close="handleClose"
  >
    <div class="content-layout">
      <div class="label-banner">
        <div class="banner-title">
          <img
            src="@/assets/order/meter.svg"
            style="width: 24px; height: 24px"
            alt=""
          />
          <span>{{
            currentRow.nodeType === 4
              ? currentRow._parent.nodeName + "-" + currentRow.nodeName
              : currentRow.nodeName
          }}</span>
        </div>
        <div class="banner-desc">编号：{{ currentRow.nodeCode }}</div>
      </div>
      <el-date-picker
        v-model="date"
        type="date"
        style="width: 360px;"
        :value-format="'yyyy-MM-dd'"
        placeholder="选择日期"
        @change="handleDateChange"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <div class="chart-box">
        <div class="chart-box-title">功率</div>
        <el-empty
          v-if="isPowerChartNull"
          style="height: 100%;width: 100%;"
          image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
          :image-size="48"
        ></el-empty>
        <v-chart
          v-else
          ref="powerChartRef"
          style="height: 276px;"
          :option="powerChartOption"
          autoresize
          manual-update
        />
      </div>
      <div class="chart-box">
        <div class="chart-box-title">电流</div>
        <el-empty
          v-if="isElectricCurrentChartNull"
          style="height: 100%;width: 100%;"
          image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
          :image-size="48"
        ></el-empty>
        <v-chart
          v-else
          ref="electricCurrentChartRef"
          style="height: 276px ;"
          :option="electricCurrentChartOption"
          autoresize
          manual-update
        />
      </div>
      <div class="chart-box">
        <div class="chart-box-title">电压</div>
        <el-empty
          v-if="isVoltageChartNull"
          style="height: 100%;width: 100%;"
          image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
          :image-size="48"
        ></el-empty>
        <v-chart
          v-else
          ref="voltageChartRef"
          style="height: 276px ;"
          :option="voltageChartOption"
          autoresize
          manual-update
        />
      </div>
    </div>
    <span class="drawer-footer">
      <div class="drawer-footer-inner">
        <el-button type="" @click="visible = false">关 闭</el-button>
      </div>
    </span>
  </el-drawer>
</template>

<script setup>
import { defineProps, computed, nextTick, ref, defineExpose } from "vue";
import VChart from "vue-echarts";
import { getConnectorMonitor } from "@/api/order";
import { getAmmeterMonitor } from "@/api/power";
import dayjs from "dayjs";
let visible = ref(false);
const currentRow = ref({});
const powerChartRef = ref();
const isPowerChartNull = ref(true);
const electricCurrentChartRef = ref();
const isElectricCurrentChartNull = ref(true);
const voltageChartRef = ref();
const isVoltageChartNull = ref(true);
const drawerTitle = ref("电表详情");

function open(row) {
  currentRow.value = row;
  console.log("🚀 ~ file: meterDetailsDrawer.vue:109 ~ row:", row);

  let { nodeType } = row;
  if (nodeType == 2) {
    drawerTitle.value = "电表详情";
    getAmmeterMonitorData();
  } else if (nodeType == 4) {
    drawerTitle.value = "终端详情";
    getElectricCurrentChart();
  }
  visible.value = true;
}
let date = ref(dayjs().format("YYYY-MM-DD"));
defineExpose({ open });

// 获取电表趋势
function getAmmeterMonitorData() {
  getAmmeterMonitor({
    ammeterId: currentRow.value.refId,
  }).then((res) => {
    setCharts(res.data || []);
  });
}
// 获取电枪趋势
function getElectricCurrentChart() {
  getConnectorMonitor({
    connectorCode: currentRow.value.nodeCode,
    date: date.value ? date.value : undefined,
  }).then((res) => {
    setCharts(res.data || []);
  });
}
const powerChartOption = ref({});
const electricCurrentChartOption = ref({});
const voltageChartOption = ref({});

function setCharts(data) {
  let isNull = data.length === 0;

  isPowerChartNull.value = isNull;
  isElectricCurrentChartNull.value = isNull;
  isVoltageChartNull.value = isNull;
  if (!isNull) {
    setTimeout(() => {
      powerChartOption.value = setChartOption(data, {
        title: "功率",
        key: "power",
        unit: "kW",
      });
      powerChartRef.value.setOption(powerChartOption.value);
      electricCurrentChartOption.value = setChartOption(data, {
        title: "电流",
        name: "实际电流",
        key: "current",
        unit: "A",
        color: "#2CE0DD",
        area: "rgba(44, 224, 221, 0.12)",
      });
      electricCurrentChartRef.value.setOption(electricCurrentChartOption.value);
      voltageChartOption.value = setChartOption(data, {
        title: "电压",
        name: "实际电压",
        key: "voltage",
        unit: "V",
        color: "#3BCBF3",
        area: "rgba(59, 203, 243, 0.12)",
      });
      voltageChartRef.value.setOption(voltageChartOption.value);
    });
  }
}

// 设置图表option
let waveIconSvgPath =
  "path://M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z";

function setChartOption(
  data,
  { title, key, unit, name, color = "#18ADD0", area }
) {
  return {
    color: [color],
    legend: {
      bottom: 10,
      icon: "circle",
      itemWidth: 10,
      textStyle: {
        color: "rgba(78, 89, 105, 1)",
      },
      icon: waveIconSvgPath,
    },
    dataset: {
      source: data,
    },
    tooltip: {
      trigger: "axis",
      icon: waveIconSvgPath,
      formatter(params) {
        let htmlText = "";
        params.forEach((item, index) => {
          if (index === 0) {
            htmlText += `<p style="color: #1D2129;font-size: 12px;">${
              item.axisValue
            }</p>`;
          }
          htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between">
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${item
            .value[item.dimensionNames[item.encode.y[0]]] ||
            "-"} ${unit}</span></p>`;
        });
        return htmlText;
      },
    },
    grid: {
      top: 30,
      right: 20,
      left: 40,
      bottom: 60,
    },
    xAxis: {
      type: "category",
      axisLine: {
        onZero: true,
        lineStyle: {
          color: "#E5E6EB",
        },
      },
      axisLabel: {
        color: "#86909C",
        // hideOverlap: true,
        showMinLabel: true,
        showMaxLabel: true,
        alignMaxLabel: data.length > 5 ? "right" : "center",
        formatter(value) {
          return dayjs(value).format("HH:mm:ss");
        },
      },
      axisTick: {
        show: false,
      },
    },
    axisPointer: {
      lineStyle: {
        color: "#C9CDD4",
        type: "solid",
      },
    },
    yAxis: {
      name: `单位 (${unit})`,
      type: "value",
      nameTextStyle: {
        color: "#86909C",
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        color: "#86909C",
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
          color: ["#E5E6EB"],
          opacity: 0.5,
        },
      },
    },
    series: [
      {
        name: name || title,
        type: "line",
        zlevel: 20,
        showSymbol: false,
        symbolSize: 8,
        smooth: true,
        encode: {
          x: "createTime",
          y: key,
        },
        tooltip: {
          valueFormatter: (value) => value + ` ${unit}`,
        },
        label: {
          color: "#18ADD0",
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: area || "rgba(24, 173, 208, 0.12)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(255, 255, 255, 0)", // 100% 处的颜色
              },
            ],
          },
        },
      },
    ],
  };
}

const pickerOptions = computed(() => {
  return {
    disabledDate(time) {
      return (
        time.getTime() <
          dayjs()
            .subtract(1, "year")
            .unix() *
            1000 || time.getTime() > dayjs().unix() * 1000
      );
    },
  };
});
function handleDateChange() {
  if (currentRow.value.nodeType == 2) {
    getAmmeterMonitorData();
  } else if (currentRow.value.nodeType == 4) {
    getElectricCurrentChart();
  }
}

function handleClose(done) {
  powerChartRef.value && powerChartRef.value.clear();
  electricCurrentChartRef.value && electricCurrentChartRef.value.clear();
  voltageChartRef.value && voltageChartRef.value.clear();
  date.value = dayjs().format("YYYY-MM-DD");
  done();
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
  border-radius: 8px 0px 0px 8px;
}
::v-deep .el-drawer__header {
  padding: 16px 24px;
  margin-bottom: 0;
  font-weight: 500;
  color: var(--PC-, #1d2129);
  border-bottom: var(--PC--, #e5e6eb) solid 1px;
}
::v-deep .el-drawer__body {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  flex-direction: column;
}
::v-deep .el-drawer__close-btn {
  position: absolute;
  right: 10px;
  color: #86909c;
}
.drawer-footer {
  display: flex;
  height: 53px;
  border-top: 1px solid var(--PC--, #e5e6eb);
  padding: 10px 0;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  .drawer-footer-inner {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
}
.content-layout {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex: 1;
  overflow: auto;
  .label-banner {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(91deg, #ebf5f7 0.07%, #f5f2f0 100%);
    .banner-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      color: var(--PC-, #1d2129);
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .banner-desc {
      color: var(--PC-, #4e5969);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
.chart-box {
  flex: 1;
  border-radius: 8px;
  border: 1px solid var(--PC--, #e5e6eb);
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 500px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  color: var(--PC-, #1d2129);
  font-weight: 500;
  line-height: normal;
}
.chart-box-title {
  margin: 16px 0 0 16px;
}
</style>
